import {motion, useCycle} from 'framer-motion'
import React from 'react'
import styled from 'styled-components'
const Path = (props) => (
  <motion.path fill="transparent" strokeWidth="3" strokeLinecap="round" {...props} />
)

const MenuToggle = ({toggle, state}) => {
  // <div className="z-50 m-4 p-2 h-2  rounded-full bg-white fixed">
  // console.log(state)
  return (
    <>
      <button className={state? `focus:outline-none absolute mt-6 mx-6   z-50` : `focus:outline-none fixed  mt-6 mx-6   z-50` } onClick={toggle}>
        <svg
          className={state ? `rounded bg-white ` : `rounded bg-gray`}
          width="30px"
          height="30px"
          viewBox="0 0 30 30"></svg>
      </button>
    </>
  )
}
export default MenuToggle


const styledSvg = styled.button`
  mix-blend-mode: difference;
`