import React, {useEffect, useState} from 'react'
import {StaticQuery, graphql} from 'gatsby'

import PropTypes from 'prop-types'

export const ProductsContext = React.createContext()
// Wrapper to give Provider access to Sku nodes from Gatsby's GraphQL store / live inventory data from stripe.
const ProductsProvider = ({children}) => (
  <StaticQuery query={skusQuery} render={(data) => <Provider data={data}>{children}</Provider>} />
)
ProductsProvider.propTypes = {
  children: PropTypes.any.isRequired,
}
/*
  Shares product information and availability through context.
  Products are first loaded from Gatsby's GraphQL store and then updated with
  current information from Stripe.
*/
const Provider = ({data, children}) => {
  /** Load product data from Gatsby store **/
  // takes query data, formats and returns two objects - initialProducts, initialSkus
  const [initialProducts, initialSkus] = processGatsbyData(data)
  // assign the state
  const [products, setProducts] = useState(initialProducts)
  const [skus, setSkus] = useState({}) // does making this an empty {} prevent it from showing wrong stock no.
  /** On render and update, update products with live data */
  useEffect(() => {
    // console.log(products)
    // console.log(skus)
    /** Query live data from Stripe and update products */
    const updateProducts = async () => {
      const {data, error} = await fetch('/.netlify/functions/skuList')
        .then((response) => response.json())
        .catch((error) => console.error(error))
      if (error) {
        console.error(error)
      }
      // use the live stripe data
      const [liveProducts, liveSkus] = processStripeData(data, products)
      console.log(liveProducts)
      setProducts(liveProducts)
      setSkus(liveSkus)
    }
    updateProducts()
  }, [])

  return (
    <ProductsContext.Provider
      value={{
        products,
        skus,
        listProducts: (sort) => {
          const fn = sort || ((a, b) => b.created - a.created)
          return Object.values(products).sort(fn)
        },
        listSkus: (sort) => {
          const fn = sort || ((a, b) => a.product.metadata.list - b.product.metadata.list)
          return Object.values(skus).sort(fn)
        },
        fetchInventory: (id) => {
          console.log(Object.values(skus).find((sku) => sku.id === id))

          return Object.values(skus).find((sku) => sku.id === id)
        },
      }}>
      {children}
    </ProductsContext.Provider>
  )
}

Provider.propTypes = {
  data: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
}

/********************************************************/

/** Normalize structure of data sourced from Gatsby's GraphQL store */
const processGatsbyData = (data) => {
  const initialProducts = {}
  const initialSkus = {}
  data.allStripeSku.group.forEach((group, i) => {
    // only one group?
    const sku = group.edges[0].node // individual sku object
    // sku.inventory.quantity = ' '
    // generate empty inv object, so rendered quantity info is always loaded from stripe
    const product = {inv: {}, slug: sku.id, ...sku.product}
    // console.log(JSON.stringify(sku.product.skus))
    product.skus = group.edges.map(({node}) => {
      // ^------------- main issue is here (I think)
      initialSkus[node.id] = node
      return node
    })
    initialProducts[product.id] = product
  })

  return [initialProducts, initialSkus]
}

/** Normalize structure of live data sourced from Stripe */
const processStripeData = (data, products) => {
  // initiliase objects to store live data in
  console.log(data)
  const liveProducts = {}
  const liveSkus = {}
  data.forEach((source) => {
    const id = source.product.id
    // console.log(source) // source sku id
    // products[id].skus.find((x) => console.log(x.id)) // <---- problem here
    const original = products[source.product.id].skus.find((x) => x.id === source.id) // sku in [] matching object source.product
    const updatedSku = Object.assign(original, source) // copies enumerable properties from source to target - returns target
    if (!liveProducts[id]) {
      source.product.inv = source.inventory
      source.product.localFiles = products[id].skus[0].localFiles
      source.product.slug = products[id].slug
      liveProducts[id] = {...source.product, skus: []}
    }
    liveProducts[id].skus.push(updatedSku)
    liveSkus[updatedSku.id] = updatedSku
  })
  return [liveProducts, liveSkus] // new product and sku context objects, used by setStateAction
}

// this is the initial query - it uses the fragment below
const skusQuery = graphql`
  query skusQuery {
    allStripeSku {
      group(field: product___id) {
        fieldValue
        edges {
          node {
            ...Sku
          }
        }
      }
    }
  }
`

export const skuFragment = graphql`
  fragment Sku on StripeSku {
    id
    currency
    price
    inventory {
      quantity
    }
    product {
      metadata {
        list
      }
      id
      name
      description
    }
    localFiles {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ProductsProvider
