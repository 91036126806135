import React, {Dispatch, Reducer, createContext, useContext, useReducer} from 'react'

import {useMediaQuery} from 'react-responsive'

interface Actions {
  type: string
  value: any
}

interface LayoutProps {
  showOutline: boolean
  isClosed: boolean
  bgClass: string
}

interface LayoutProviderProps {
  reducer: Reducer<LayoutProps, Actions>
  initState: LayoutProps
}

interface InitContextProps {
  state: LayoutProps
  dispatch: Dispatch<Actions>
}

export const LayoutContext = createContext({} as InitContextProps)
const LayoutContextProvider: React.FC<LayoutProviderProps> = ({reducer, initState, children}) => {
  const [state, dispatch] = useReducer(reducer, initState)
  const value = {state, dispatch}
  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
}
//use this as custom hook -  {state, dispatch} = useLayoutValue()
export const useLayoutValue = () => useContext(LayoutContext)

// wrap this around root
const LayoutController: React.FC = ({children}) => {
  const isMobile = useMediaQuery({maxWidth: 1224})
  console.log(isMobile)
  const initState: LayoutProps = {
    showOutline: true,
    bgClass: 'bg-main',
    isClosed: isMobile ? true : false,
  }

  // Reducer
  const reducer: Reducer<LayoutProps, Actions> = (state, action) => {
    switch (action.type) {
      case 'SET_BORDER':
        return {
          ...state,
          showOutline: action.value,
        }

      case 'SET_MENU':
        return {
          ...state,
          isClosed: action.value,
        }
      case 'SET_BG':
        return {
          ...state,
          bgClass: action.value,
        }

      default:
        return state
    }
  }
  return (
    <LayoutContextProvider reducer={reducer} initState={initState}>
      {children}
    </LayoutContextProvider>
  )
}

export default LayoutController
