// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-checkout-address-tsx": () => import("./../../../src/pages/checkout/address.tsx" /* webpackChunkName: "component---src-pages-checkout-address-tsx" */),
  "component---src-pages-checkout-details-tsx": () => import("./../../../src/pages/checkout/details.tsx" /* webpackChunkName: "component---src-pages-checkout-details-tsx" */),
  "component---src-pages-checkout-payment-tsx": () => import("./../../../src/pages/checkout/payment.tsx" /* webpackChunkName: "component---src-pages-checkout-payment-tsx" */),
  "component---src-pages-checkout-success-tsx": () => import("./../../../src/pages/checkout/success.tsx" /* webpackChunkName: "component---src-pages-checkout-success-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-tsx": () => import("./../../../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */),
  "component---src-templates-art-template-tsx": () => import("./../../../src/templates/art-template.tsx" /* webpackChunkName: "component---src-templates-art-template-tsx" */),
  "component---src-templates-work-template-tsx": () => import("./../../../src/templates/work-template.tsx" /* webpackChunkName: "component---src-templates-work-template-tsx" */)
}

