export const formatPrice = (amount, currency) => {
  let price = (amount / 100).toFixed(10)
  let numberFormat = new Intl.NumberFormat(['en-US'], {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
  })
  return numberFormat.format(price)
}

export const generateSerial = () => {
  const d = new Date()
  const year = d.getFullYear()
  const month = d.getMonth() + 1
  const day = d.getDay()
  const hour = d.getHours()
  const min = d.getMinutes()
  const sec = d.getSeconds()
  const ms = d.getMilliseconds()
  const serial = '' + ms + sec + min + hour + month + day + year
  // console.log(serial);
  return serial
}
