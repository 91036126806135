import React, {useEffect, useState} from 'react'
import {motion, useCycle} from 'framer-motion'

import BarCode from '../../images/svgs/barcode.svg'
import Cart from '../gallery/cart/Cart'
import {Link} from 'gatsby'
import MenuToggle from './MenuToggle'
import {generateSerial} from '../shared/utils'
import {useLayoutValue} from './layoutProvider'
import {useMediaQuery} from 'react-responsive'

const Header: React.FC<any> = ({menuLinks, menuState, bind}) => {
  const {state, dispatch} = useLayoutValue()
  const isMobile = useMediaQuery({maxWidth: 1224})

  return (
    <>
      <MenuToggle
        state={state.isClosed}
        toggle={() => dispatch({type: 'SET_MENU', value: !state.isClosed})}
      />
      {/* <Cart /> */}
      <motion.header
        {...bind}
        initial={'open'}
        animate={state.isClosed ? 'closed' : 'open'}
        className="flex-none inset-x-0 md:relative overflow-hidden   bg-black md:border-none  min-h-screen"
        variants={isMobile ? mobileSidebar : sidebar}>
        <motion.div
          className="flex justify-between  fixed flex-col h-screen pl-6"
          variants={menuVariants}>
          {/* spacer div */}
          <div className="">
            <span className="z-50 text-2xl text-black font-sans"></span>
          </div>
          {/* menu */}
          <nav className="flex-none align-middle">
            <ul className="">
              {menuLinks.map((link) => (
                <li
                  className="pt-2"
                  key={link.name}
                  style={{
                    listStyleType: `none`,
                  }}>
                  <Link
                    // close nav on route change for mobile only
                    // TODO: encapsulate click handler logic into seperate function
                    onClick={
                      isMobile
                        ? () => dispatch({type: 'SET_MENU', value: !state.isClosed})
                        : (e) => e.preventDefault
                    }
                    className="hover:underline text-white "
                    to={link.link}>
                    {link.name}
                  </Link>
                </li>
              ))}
              {/* <li className="hover:underline text-white pt-2">
                <a href="mailto:72.1900@gmail.com">CONTACT</a>
              </li> */}
            </ul>
          </nav>
          <footer className="flex-initial text-white mb-6 md:my-0">
            {/* <Link
              onClick={
                isMobile
                  ? () => dispatch({type: 'SET_MENU', value: !state.isClosed})
                  : (e) => e.preventDefault
              }
              className="hover:underline font-light font-sm"
              to="/faq">
              faq{' '}
            </Link> */}
            <Link
              onClick={
                isMobile
                  ? () => dispatch({type: 'SET_MENU', value: !state.isClosed})
                  : (e) => e.preventDefault
              }
              to="/">
              <p className="font-mono justify-center text-xss mt-2 md:mb-4">
                {/* <img src={barCode}></img> */}
                <BarCode />© ajdore<br></br>
                {generateSerial()}
                {/* {Date.now()} */}
              </p>
            </Link>
          </footer>
        </motion.div>
      </motion.header>
      {/* </div> */}
    </>
  )
}

// motion variants
const sidebar = {
  open: {
    width: '14rem',
    transition: {
      delay: 0.3,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
  closed: {
    width: '0%',
    transition: {
      delay: 0.3,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
}
const mobileSidebar = {
  open: {
    width: '100vw',
    transition: {
      delay: 0.3,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
  closed: {
    width: '0%',
    transition: {
      delay: 0.3,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
}

const menuVariants = {
  open: {
    x: 0,
    transition: {
      delay: 0.3,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
  closed: {
    x: -120,
    transition: {
      delay: 0.3,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
}

export default Header
