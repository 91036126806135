import {Link, StaticQuery, graphql, useStaticQuery} from 'gatsby'
import {useContext, useEffect, useState} from 'react'

import DollarSvg from '../../images/svgs/dollar.svg'
import Img from 'gatsby-image'
import NewTab from '../../images/svgs/newtabthin.svg'
import React from 'react'

const MdxImg = ({name, available}) => {
  const [img, setImg] = useState()
  const [src, setSrc] = useState()
  const [path, setPath] = useState('')

  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            id
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
              originalName
              src
            }
            original {
              src
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    data.allImageSharp.edges.map((edge) => {
      edge.node.fluid.originalName === name ? setImg(edge.node.fluid) : ''
      edge.node.fluid.originalName === name ? setSrc(edge.node.original.src) : ''
      edge.node.fluid.originalName === name ? setPath(edge.node.fluid.originalName) : ''
    })
  }, [])

  return (
    <div className="flex flex-col w-full md-w:1/4 mb-2 ">
      <Img className=" m-2 rounded" fluid={img}></Img>
      <div className="flex flex-row justify-end my-2">
        {available ? (
          <Link to={`/gallery/${path.slice(0, -4)}`}>
            <DollarSvg className="h-8 mr-2" />
          </Link>
        ) : (
          ''
        )}
        <a className="h-8 self-end  " target="_blank" href={src}>
          <NewTab className="h-8 mr-2  " />
        </a>
      </div>
    </div>
  )
}

export default MdxImg
