import React, {useContext, useEffect, useState} from 'react'
import {graphql, useStaticQuery} from 'gatsby'

import Cart from '../../pages/cart'
import Header from './header'
import {motion} from 'framer-motion'
import {useLayoutValue} from './layoutProvider'
import useMeasure from '../shared/useMeasure'
import {useMediaQuery} from 'react-responsive'

// import {layoutController, useLayout} from './layoutProvider'

const Layout: React.FC<ChildNode> = ({children}) => {
  const {state, dispatch} = useLayoutValue()
  const [calcWidth, setCalcWidth] = useState('')
  const isMobile = useMediaQuery({query: '(min-device-width: 640px)'})
  // no typings for this
  const [bind, {width}] = useMeasure()

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          menuLinks {
            name
            link
          }
        }
      }
    }
  `)
  return (
    <>
      <div className="flex flex-row font-sans min-h-screen overflow-hidden ">
        {/* {isMobile ? dispatch({type: 'SET_MENU', value: true}) : ''} */}
        <Header
          bind={bind}
          menuState={isMobile ? true : false}
          siteTitle={data.site.siteMetadata.title}
          menuLinks={data.site.siteMetadata.menuLinks}
        />
        {/* TODO: fix this resize shit */}
        <div
          style={{width: `calc(100% - ${width}px)`}}
          className={`${state.bgClass} flex flex-col`}>
          {/* <div className={state.showOutline ? `bg-main h-full ` : `h-full `}> */}
          {/* <div className={state.isClosed ? `border rounded m-10 absolute h-full w-full` : `hidden` }/> */}
          {children}
          {/* </div> */}
        </div>
      </div>
    </>
  )
}

export default Layout
