import CartProvider from './src/components/gallery/cart/CartProvider'
import {Elements} from '@stripe/react-stripe-js'
import Layout from './src/components/ui/layout'
import LayoutController from './src/components/ui/layoutProvider'
import ProductsProvider from './src/components/gallery/products/ProductsProvider'
import React from 'react'
import {loadStripe} from '@stripe/stripe-js'
// import {useMediaQuery} from 'react-responsive'

export const wrapPageElement = ({element}) => {
  const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY)
  return (
    <Elements stripe={stripePromise}>
      <ProductsProvider>
        <CartProvider>
          <LayoutController>
            <Layout>{element} </Layout>
          </LayoutController>
        </CartProvider>
      </ProductsProvider>
    </Elements>
  )
}
